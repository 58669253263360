<template>
  <li class="dashboard-detail-list-element" id="${juniorUid}">
    <div class="dashboard-detail-list-element-profile">
      <div class="dashboard-detail-list-element-profile-image">
        <img
          class="round-image"
          v-if="profilePic"
          :src="profilePic"
          alt="avatar"
        />
        <img
          class="round-image"
          v-else
          src="@/images/default-avatar.png"
          alt="avatar"
        />
      </div>
      <div class="dashboard-detail-list-element-profile-info">
        <h3>
          <u>{{ candidate.firstName + " " + candidate.lastName }}</u> requested
          a referral to <u>{{ oppData.title }}</u> at
          <u>{{ oppData.company }}</u
          >.
        </h3>
        <div class="profile-info-second-line">
          <h4>{{ candidate.major }}</h4>
          <h4 v-if="candidate.GPA">Cumulative GPA: {{ candidate.GPA }}</h4>
          <h4 v-else>This student does not have a GPA in profile.</h4>
        </div>
      </div>
      <div
        class="dashboard-detail-list-element-profile-btns"
        v-if="statusHolder == 'pending'"
      >
        <div class="decline-accept" v-if="!confirmDecline&!confirmAccept">
          <button
            class="dashboard-detail-decline-btn"
            @click="confirmDecline = !confirmDecline;"
          >
            <p>Decline</p>
          </button>
        
          <button
            class="dashboard-detail-accept-btn"
            @click="confirmAccept = !confirmAccept;"
          >
            <p>Accept</p>
          </button>
        </div>

        <div class="confirm-btns" :class="{'confirm-btns-hidden':!confirmDecline&&!confirmAccept}">
          <button
            v-if="confirmDecline"
            class="dashboard-detail-decline-btn confirm-decline"
            @click="
              changeStatus('declined', [0, -1, 0, 1]);
              statusHolder = 'declined';
              confirmDecline = !confirmDecline;
            "
          >
            <p>Confirm Decline</p>
          </button>
        
          <button
            v-if="confirmAccept"
            class="dashboard-detail-accept-btn confirm-accept"
            @click="
              changeStatus('accepted', [0, -1, 1, 0]);
              statusHolder = 'accepted';
              confirmAccept = !confirmAccept;
            "
          >
            <p>Confirm Accept</p>
          </button>
        </div>
        
      </div>
      <div
        class="dashboard-detail-list-element-profile-status"
        v-if="statusHolder == 'accepted'"
        @mouseover="resetTrigger = false"
        @mouseleave="resetTrigger = false"
        @dragenter="
          changeStatus('pending', [0, 1, -1, 0]);
          statusHolder = 'pending';
        "
      >
        <div class="green" v-show="!resetTrigger">
          <ion-icon name="checkmark-outline" class="small-icon"></ion-icon>
        </div>
        <h3 class="green" v-show="!resetTrigger">Accepted</h3>
        <div class="grey" v-show="resetTrigger">
          <ion-icon name="sync-outline" class="small-icon"></ion-icon>
        </div>
        <h3 class="grey" v-show="resetTrigger">Reset</h3>
      </div>
      <div
        class="dashboard-detail-list-element-profile-status"
        v-if="statusHolder == 'declined'"
        @mouseover="resetTrigger = false"
        @mouseleave="resetTrigger = false"
        @dragenter="
          changeStatus('pending', [0, 1, 0, -1]);
          statusHolder = 'pending';
        "
      >
        <div class="red" v-show="!resetTrigger">
          <ion-icon name="close-outline" class="small-icon"></ion-icon>
        </div>
        <h3 class="red" v-show="!resetTrigger">Declined</h3>
        <div class="grey" v-show="resetTrigger">
          <ion-icon name="sync-outline" class="small-icon"></ion-icon>
        </div>
        <h3 class="grey" v-show="resetTrigger">Reset</h3>
      </div>
    </div>
    <hr class="good-line-top"/>
    
    
    <div :class="{'collapse-content-container':true, 'collapse-content-containerFolded':isCollapsed}">
      <div class="profile-detail-content">
        <div class="about">
          <h3><u>About</u></h3>
          <h4>{{ candidate.about }}</h4>
        </div>
        <div class="exp-title">
          <h3><u>Experience</u></h3>
          <h4 v-if="candidate.experience.length == 0">
            This student has not added any experience yet.
          </h4>
        </div>
        <ul class="experience">
          <li class="my-exp" v-for="exp in candidate.experience" :key="exp.id">
            <div class="my-exp-top">
              <h2>{{ exp.title }}</h2>
              <h4 v-if="exp.currentInRole">
                {{ modifyDate(exp.startDate.toDate()) }} - Present
              </h4>
              <h4 v-if="!exp.currentInRole">
                {{ modifyDate(exp.startDate.toDate()) }} -
                {{ modifyDate(exp.endDate.toDate()) }}
              </h4>
            </div>
            <h4>{{ exp.company }}</h4>
            <h4>{{ exp.description }}</h4>
          </li>
        </ul>
      </div>
    </div>

    <hr :class="{'good-line-bot':true, 'line-invisible':isCollapsed}"/>
    <div class="expand-collapse-resume">
      <div
        class="expand-collapse collapsed"
        @click="isCollapsed = !isCollapsed"
      >
        <div class="expand-collapse-arrow">
          <ion-icon
            name="chevron-down-outline"
            class="up-down big-icon"
            v-show="isCollapsed"
          ></ion-icon>
          <ion-icon
            name="chevron-up-outline"
            class="up-down big-icon"
            v-show="!isCollapsed"
          ></ion-icon>
        </div>
        <h4 class="more-less" v-show="isCollapsed">
          Show more
        </h4>
        <h4 class="more-less" v-show="!isCollapsed">
          Show less
        </h4>
      </div>
      <div class="empty-block"></div>
      <a
        :href="resume"
        target="_blank"
        class="see-resume"
        :class="{ 'empty-resume': resume == null }"
      >
        <ion-icon name="document-text-outline" class="big-icon"></ion-icon>
        <p v-if="resume == null">No Resume</p>
        <p v-else>See Resume</p>
      </a>
    </div>
  </li>
</template>

<script>
import { ref, onMounted } from "vue";
import getProfile from "@/composables/Profile/getProfile";
import changeOppStatus from "@/composables/Opp/changeOppStatus";
import sendAcceptNote from "@/composables/Email/sendAcceptNote";
import getUrlAndName from "@/composables/Storage/getUrlAndName";
import getUser from "@/composables/getUser";
import { changeMyOppsStatus } from "@/composables/Profile/modifyMyOpps";

export default {
  props: ["candidate", "oppData"],
  emits: ["change-status"],
  async setup(props, { emit }) {
    const profile = ref(null);
    const isCollapsed = ref(true);
    const resetTrigger = ref(false);
    const profilePic = ref(null);
    const resume = ref(null);
    const statusHolder = ref(props.candidate.status);
    const confirmDecline = ref(false);
    const confirmAccept = ref(false);

    await getProfile(profile, props.oppData.alumniId);
    await getUrlAndName(profilePic, null, props.candidate.uid, "Images");
    await getUrlAndName(resume, null, props.candidate.uid, "Resumes");

    const modifyDate = (dateHold) => {
      return (
        dateHold.getMonth() +
        1 +
        "/" +
        dateHold.getDate() +
        "/" +
        dateHold.getFullYear()
      );
    };

    const changeStatus = async (decision, statusArr) => {
      await changeOppStatus(props.oppData.id, props.candidate.uid, decision);
      await changeMyOppsStatus(props.candidate.uid, props.oppData.id, decision);
      emit("change-status", statusArr);
      if (decision == "accepted") {
        await sendAcceptNote(
          props.candidate.firstName,
          props.candidate.email,
          profile.value.firstName + " " + profile.value.lastName,
          { position: props.oppData.title, company: props.oppData.company }
        );
      }
    };

    return {
      profilePic,
      resume,
      isCollapsed,
      statusHolder,
      resetTrigger,
      modifyDate,
      changeStatus,
      confirmDecline,
      confirmAccept,
    };
  },
};
</script>

<style scoped>
* {
  font-size: 100%;
  font-weight: 400;
}

.good-line-top {
  margin: 0vh;
}

.good-line-bot {
  margin-bottom: 2vh;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.line-invisible {
  opacity: 0;
  transition: opacity 0.25s ease;
}

.dashboard-detail-list-element {
  border: 2px rgb(221, 221, 221) solid;
  border-radius: 1vh;
  padding: 1.5vh 1.5vw;
  margin: 2vh 0;
  animation: fadein 0.5s;
}

.dashboard-detail-list-element-profile {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5vh;
}

.dashboard-detail-list-element-profile-image {
  flex: 0 1 8%;
  display: flex;
  align-items: center;
}

.dashboard-detail-list-element-profile-info {
  flex: 0 1 62%;
}

.dashboard-detail-list-element-profile-info h3 {
  margin-bottom: 1.5vh;
}

.profile-info-second-line h4 {
  font-size: 90%;
  background-color: rgb(238, 238, 238);
  display: inline-block;
  padding: 1vh 1vw;
  border-radius: 1vh;
  margin-right: 1vw;
}

.dashboard-detail-list-element-profile-image img {
  height: 4vw;
  width: 4vw;
}

.dashboard-detail-list-element-profile-btns {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-detail-list-element-profile-status {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadein 0.5s;
}

.dashboard-detail-list-element-profile-status div {
  flex: 0 0 4vw;
  padding-left: 10vw;
  padding-right: 1vw;
}

.dashboard-detail-list-element-profile-status h3 {
  font-weight: 400;
  font-size: 100%;
  flex: 1 0 auto;
  padding: 0 0;
}

.decline-accept{
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.decline-accept-hidden{
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.confirm-btns{
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.confirm-btns-hidden{
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.dashboard-detail-decline-btn {
  width: 100px;
  padding: 1vh 1vw;
  border: 1.5px var(--red) solid;
  color: var(--red);
  background-color: white;
  border-radius: 0.5vw;
  margin-right: 15px;
  font-size: 100%;
  transition: width 0.5s ease;
  overflow: hidden;
}

.dashboard-detail-decline-btn:hover {
  border-color: var(--dark-red);
  color: var(--dark-red);
  cursor: pointer;
}

.dashboard-detail-decline-btn p {
  display: inline;
  animation: fadein 0.5s;
}

.confirm-decline {
  border-color: rgb(252, 91, 91);
  color: rgb(252, 91, 91);
  width: 215px;
  transition: width 0.5s ease;
}

.confirm-decline:hover {
  border-color: red;
  color: red;
}

.dashboard-detail-accept-btn {
  width: 100px;
  padding: 1vh 1vw;
  border: 1.5px var(--purple-l) solid;
  color: var(--purple-l);
  border-radius: 0.5vw;
  background-color: white;
  font-size: 100%;
  transition: width 0.5s ease;
}

.dashboard-detail-accept-btn:hover {
  border-color: var(--purple);
  color: var(--purple);
  cursor: pointer;
}

.dashboard-detail-accept-btn p {
  display: inline;
}

.confirm-accept {
  width: 215px;
  transition: width 0.5s ease;
}

.collapse-content-container {
  max-height: 100vh;
  transform-origin: top;
  transition: all 1s ease;
  overflow: hidden;
}

.collapse-content-containerFolded {
  max-height: 0vh;
  transform-origin: top;
  transition: all 0.5s ease;
  overflow: hidden;
}

.profile-detail-content {
  padding: 1vh 0 2vh 0;
}

.about {
  padding-bottom: 1vh;
}

.about h3 {
  font-size: 120%;
  line-height: 5vh;
}

.about h4 {
  font-size: 90%;
  font-weight: 50;
}

.my-exp h2 {
  font-size: 120%;
  line-height: 3vh;
}

.my-exp h4 {
  font-size: 90%;
  font-weight: 50;
}

.expand-collapse-resume {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.expand-collapse {
  flex: 0 0 300px;
  width: 100%;
  display: flex;
  color: grey;
}

.expand-collapse:hover {
  color: black;
  cursor: pointer;
}

.expand-collapse-arrow {
  flex: 0 0 30px;
  align-self: center;
  transition: all 0.25s ease;
}

.expand-collapse h4 {
  font-weight: 75;
  flex: 1 0 auto;
  align-self: center;
  transition: all 0.25s ease;
}

.empty-block {
  flex: 1 1 auto;
}

.see-resume {
  color: black;
  flex: 0 0 150px;
  display: flex;
  align-self: center;
}

.see-resume:hover {
  cursor: pointer;
  color: #cca2d8;
}

.empty-resume:hover {
  cursor: auto;
  color: black;
}

.see-resume ion-icon {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 5px 0 15px;
  font-size: 125%;
}

.see-resume p {
  flex: 0 0 auto;
  padding: 0 5px;
}

.experience {
  list-style: none;
}

.my-exp-top {
  display: flex;
  align-items: center;
}

.my-exp-top h2 {
  flex: 0 1 70%;
}

.my-exp-top h4 {
  flex: 1 0 auto;
}

.exp-title h3 {
  font-size: 120%;
  line-height: 5vh;
}

.exp-title h4 {
  font-size: 90%;
  font-weight: 50;
}

@media screen and (max-width: 630px) {
  .dashboard-detail-list-element-profile-info {
    flex: 1 1 62%;
  }

  .dashboard-detail-list-element-profile-image {
    display: none;
  }
}
</style>
